import { Component, useEffect } from 'react';
import ApiService from "../services/api.service";
import { useLocation } from 'react-router-dom';

const ErrorBoundary = ({ children, pageLocation, setPageLocation }) => {
    const location = useLocation();

    useEffect(() => {
        setPageLocation(location);
    })

    if (pageLocation !== location) {
        return;
    }
    return (
        <ErrorBoundaryInner>
            {children}
        </ErrorBoundaryInner>
    );
}

class ErrorBoundaryInner extends Component {

    state = {
        hasError: false,
        errormessage: ''
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            errormessage: error
        };
    }

    componentDidMount() {
        if (global.config === 'NOT_ALLOWED_DOMAIN' || global.config === 'SERVER_NO_ANSWER') {
            this.setState({ hasError: true, errormessage: global.config });
        }
    }

    componentDidCatch(error) {
        if (!this.state.hasError) {
            if (error.name === 'AxiosError') {
                this.setState({ errormessage: error.code });
            }
            else {
                this.setState({ errormessage: error });
            }
        }
    }

    render() {

        if (this.state.hasError) {
            let contentsection;
            let reacterror = false;
            switch (this.state.errormessage) {
                case 'ERR_NETWORK':
                    contentsection = <p><strong>Die Verbindung zu unserem Server wurde leider unterbrochen.</strong><br /><br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                    break;
                case 'SERVER_NO_ANSWER':
                    contentsection = <p>Der Server hat leider keine Antwort geliefert.</p>
                    break;
                case 'NO_CONNECTION':
                    contentsection = <p>Derzeit ist leider keine Verbindung zu unserem Server möglich.<br /><br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                    break;
                case 'NO_CODES':
                    contentsection = <p>Es ist kein Kontingent verfügbar um die Bestellung abzuarbeiten.<br /><br />Bitte hinterlegen Sie die erforderlichen Gutscheincodes</p>
                    break;
                case 'NO_ACCESS':
                    contentsection = <p>Der Zugriff auf diese Resource ist leider nicht möglich.<br /><br />Bitte versuchen Sie es erneut.</p>
                    break;
                default:
                    reacterror = true
                    contentsection = <p>Wir wurden bereits informiert und versuchen den Fehler schnellstmöglich zu beheben.</p>
                    break;
            }


            if (reacterror && typeof (global.backendconfig.expressserver) !== 'undefined') {
                const API_URL = global.backendconfig.expressserver.url + '/senderrormail';
                const API_DATA = {
                    'url': window.location.href,
                    'error': this.state.errormessage.message,
                    'errorstack': this.state.errormessage.stack,
                    'agent': navigator.userAgent,
                }
                // ApiService.getData(API_URL, API_DATA);
                reacterror = false
            }


            return (
                <div className='content t-center'>
                    <h1>Ein Fehler ist aufgetreten</h1>
                    {contentsection}
                </div>
            );
        }
        return this.props.children;
    }

}



export default ErrorBoundary;
